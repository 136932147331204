import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "green--text"
}
const _hoisted_2 = {
  key: 0,
  class: "red--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items
  }, {
    "body-cell-credit-slot": _withCtx((props) => [
      (props.row.credit)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.numberToPrice(props.row.credit)), 1))
        : _createCommentVNode("", true)
    ]),
    "body-cell-debit-slot": _withCtx((props) => [
      (props.row.debit)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.numberToPrice(props.row.debit)), 1))
        : _createCommentVNode("", true)
    ]),
    "body-cell-type-slot": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(props.row.type)), null, 16)
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}