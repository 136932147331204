import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reporting_filter = _resolveComponent("reporting-filter")!
  const _component_statements_table = _resolveComponent("statements-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_reporting_filter),
      _createVNode(_component_statements_table, {
        headers: _ctx.tableHeaders,
        items: _ctx.tableItems,
        "hide-actions": "",
        "items-name": "statements"
      }, null, 8, ["headers", "items"])
    ]),
    _: 3
  }))
}