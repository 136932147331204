
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import DateFilterDropdown from '@/components/layout/topFilter/dateFilter/DateFilterDropdown.vue'
import DateFilterCustom from '@/components/layout/topFilter/dateFilter/DateFilterCustom.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { getTableData } from '@/services/tableService'

const reportingCategories = getTableData('reportingCategories')

export default defineComponent({
  components: {
    DateFilterCustom,
    DateFilterDropdown,
    TmDropdownDownload,
    TopFilter,
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    const accounts = ref(reportingCategories[0])
    const dateFilter = ref('all')
    const customDate = ref()

    return {
      accounts,
      dateFilter,
      isSmMax,
      customDate,
    }
  },
})
