
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import StatementsTable from '@/components/pages/reporting/statements/StatementsTable.vue'
import ReportingFilter from '@/components/pages/reporting/ReportingFilter.vue'
import { getTableData } from '@/services/tableService'
import { numberToPrice } from '@/services/utils'
import type{ TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    ReportingFilter,
    StatementsTable,
    PageContent,
  },
  setup() {
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Date', value: 'date' },
      { text: 'Credit', value: 'credit-slot' },
      { text: 'Debit', value: 'debit-slot' },
      { text: 'Type', value: 'type-slot' },
      { text: 'Description', value: 'description' },
      { text: 'Balance', value: 'balance', format: (val: string) => numberToPrice(+val) },
    ])
    const tableItems = ref(getTableData('statements'))

    return {
      tableHeaders,
      tableItems,
    }
  },
})
